const mainNavHeightXl = 60;
const mainNavHeight = 56;
const menuButton = document.querySelector('.navbar-toggle');

const myOffcanvas = document.getElementById('main-nav');
const icon = menuButton.querySelector('uxt-icon');

const backdrop = document.querySelector('.menu-page-backdrop');

myOffcanvas.addEventListener('show.bs.offcanvas', function () {
  icon.setAttribute('icon', 'x-lg');
  menuButton.setAttribute('aria-label', 'Closes site menu options.');
});

myOffcanvas.addEventListener('hide.bs.offcanvas', function () {
  icon.setAttribute('icon', 'list');
  menuButton.setAttribute('aria-label', 'Opens site menu options.');
  hideSubMenus();
});

const level2Menus = document.querySelectorAll('.level2Menu');
const level3Menus = document.querySelectorAll('.level3Menu');

level2Menus.forEach((menu) => {
  const menuButton = document.querySelector(`[aria-controls="${menu.id}"]`);
  menu.addEventListener('show.bs.offcanvas', function () {
    hideAllLevel3Menus();
    menuButton.classList.add('active');
    menuButton.setAttribute('aria-label', 'Closes site menu options.');
  });

  menu.addEventListener('shown.bs.offcanvas', function () {
    focusOnFirstActionableElement(menu);
  });

  menu.addEventListener('hide.bs.offcanvas', function () {
    hideAllLevel3Menus();
    menuButton.classList.remove('active');
    menuButton.setAttribute('aria-label', 'Opens site menu options.');
  });
});

level3Menus.forEach((menu) => {
  const menuButton = menu.previousElementSibling;
  menuButton.addEventListener('click', () => {
    if (!menu.classList.contains('show')) {
      hideAllLevel3Menus();
      const navHeight = window.innerWidth > 1200 ? mainNavHeightXl : mainNavHeight;
      menu.style.top = `-${menuButton.getBoundingClientRect().top - navHeight}px`;
      menu.classList.add('show');
      focusOnFirstActionableElement(menu);
      menuButton.setAttribute('aria-expanded', 'true');
    } else {
      menu.classList.remove('show');
      menu.previousElementSibling.setAttribute('aria-expanded', 'false');
    }
  });
});

const hideAllLevel3Menus = () => {
  level3Menus.forEach((menu) => {
    menu.classList.remove('show');
    menu.previousElementSibling.setAttribute('aria-expanded', 'false');
  });
};

const hideSubMenus = () => {
  hideAllLevel3Menus();
  level2Menus.forEach((menu) => {
    menu.classList.remove('show');
    menu.previousElementSibling.setAttribute('aria-expanded', 'false');
  });
};

const focusOnFirstActionableElement = (menu) => {
  const firstActionableElement = menu.querySelector('a, button');
  if (firstActionableElement) {
    firstActionableElement.focus();
  } else {
    menu.focus();
  }
};

const backBtns = document.querySelectorAll('.menu-back-btn');

backBtns.forEach((btn) => {
  btn.addEventListener('click', () => {
    const menu = btn.closest('div');
    menu.classList.remove('show');
    menu.previousElementSibling.setAttribute('aria-expanded', 'false');
    focusOnFirstActionableElement(menu);
  });
});

const dropdownBtns = document.querySelectorAll('#main-menu > li > button');

dropdownBtns.forEach((btn) => {
  btn.addEventListener('click', () => {
    const menu = btn.nextElementSibling;
    if (!menu.classList.contains('show')) {
      hideSubMenus();
      removeActiveClasses();
      btn.classList.add('active');
      backdrop.classList.add('show');
      menu.classList.add('show');
      focusOnFirstActionableElement(menu);
    } else {
      hideSubMenus();
      menu.classList.remove('show');
      backdrop.classList.remove('show');
      btn.classList.remove('active');
    }
  });
});

const toggleMenuBackdrop = () => {
  if (backdrop.classList.contains('show')) {
    backdrop.classList.remove('show');
  } else {
    backdrop.classList.add('show');
  }
};

const removeActiveClasses = () => {
  dropdownBtns.forEach((btn) => {
    btn.classList.remove('active');
  });
};

backdrop.addEventListener('click', () => {
  hideSubMenus();
  removeActiveClasses();
  toggleMenuBackdrop();
});

level3Menus.forEach((menu) => {

  const filterInput = menu.querySelector('input[type="text"]');
  const numResultsMsg = menu.querySelector('.number-of-results');
  const menuItems = menu.querySelectorAll('ul li a');
  const filterDiv = menu.querySelector('.menu-filter');
  const menuUl = menu.querySelector('ul');
  const debouncedFilterMenuItems = debounce(() => filterMenuItems(menu, filterInput, menuItems, numResultsMsg), 250);
  const debouncedScrollHandler = debounce(() => handleScroll(menuUl, filterDiv), 250);

  filterInput.addEventListener('keyup', debouncedFilterMenuItems);
  menuUl.addEventListener('scroll', debouncedScrollHandler);

});

const filterMenuItems = (menu, filterInput, menuItems, numResultsMsg) => {
  const filter = filterInput.value.toLowerCase();
  menuItems.forEach((item) => {
    const text = item.textContent.toLowerCase();
    item.parentElement.style.display = text.includes(filter) ? 'block' : 'none';
  });

  const results = updateResultCount(menuItems, numResultsMsg);
  menu.setAttribute('data-result-count', results);
  menu.setAttribute('data-filter-text', filter);
};

const updateResultCount = (menuItems, numResultsMsg) => {
  const resultCount = Array.from(menuItems).filter(item => item.parentElement.style.display !== 'none').length;
  numResultsMsg.textContent = `Showing ${resultCount} result${resultCount !== 1 ? 's' : ''}`;
  return resultCount;
};

function debounce(func, delay) {
  let timeoutId;
  return function(...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

const handleScroll = (menuUl, filterDiv) => {
  if (menuUl.scrollTop > 0) {
    filterDiv.classList.add('shadow-sm');
  } else {
    filterDiv.classList.remove('shadow-sm');
  }
};